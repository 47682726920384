import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import ImageApp from "react-bootstrap/Image";

const Image = ({ fileName, alt, ...restProps }) => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        images: allFile {
          edges {
            node {
              relativePath
              extension
              publicURL
              childImageSharp {
                sizes(maxWidth: 1920) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find(
        (n) => n.node.relativePath.includes(fileName));
      if (!image) return null;
      if (!image.node) return null;
      if (!image.node.childImageSharp) return null;
      const extension = image.node.extension;
      if (extension === "svg" || extension === "gif") return (
        <ImageApp alt={alt} src={image.node.publicURL}/>
      );

      const imageSizes = image.node.childImageSharp.sizes;
      return <Img alt={alt} fluid={imageSizes} {...restProps}/>;
    }}
  />
);

Image.propTypes = {
  fileName: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Image.defaultProps = {
  alt: null,
};

export default Image;
